import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactComponent as MinusSVG } from '../../assets/icons/minus.svg';
import { ReactComponent as PlusSVG } from '../../assets/icons/plus.svg';
import { centerContent } from '../../styles/base';

export interface Props {
  backgroundColor?: string;
  diameter?: number;
  disabled?: boolean;
  id: string;
  isMinus?: boolean;
  onClick: () => void;
}

const Button = styled.button<{
  diameter: number;
  disabled?: boolean;
}>(({ diameter, disabled, theme: { colours } }) => [
  centerContent,
  css`
    width: ${diameter}px;
    height: ${diameter}px;
    padding: 0;
    border: 2px solid ${colours.border.interactive};
    border-radius: 50%;
    margin: 0;
    background: ${colours.surface.main};
    cursor: pointer;
    transition: background 0.15s;

    svg * {
      fill: ${colours.icons.default};
    }
  `,
  disabled
    ? css`
        border-color: ${colours.icons.disabled};
        background: ${colours.surface.disabled};
        color: ${colours.icons.disabled};
        cursor: not-allowed;

        svg * {
          fill: ${colours.icons.disabled};
        }
      `
    : css`
        &:hover,
        &:focus {
          background: ${colours.surface.hover};
        }
      `,
]);

const StyledMinusSVG = styled(MinusSVG)`
  width: 100%;
`;

const StyledPlusSVG = styled(PlusSVG)`
  width: 100%;
`;

const CounterButton = ({
  diameter = 32,
  disabled,
  id,
  isMinus,
  onClick,
}: Props) => {
  return (
    <Button diameter={diameter} disabled={disabled} id={id} onClick={onClick}>
      {isMinus ? <StyledMinusSVG /> : <StyledPlusSVG />}
    </Button>
  );
};

export default CounterButton;
