import styled from '@emotion/styled';
import hideVisually from 'polished/lib/mixins/hideVisually';
import { createTypography } from '../../styles/base';

const Label = styled.label<{ labelHidden?: boolean }>(
  ({ labelHidden, theme: { typography } }) => [
    createTypography(typography.body02),
    labelHidden && hideVisually(),
  ],
);

export default Label;
